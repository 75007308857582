import { useStaticQuery, graphql } from "gatsby";

export const useRightSideBlog = () => {
    const data = useStaticQuery(graphql`{
        popularBlog: allWpPost(
            limit: 10
            filter: {categories: {nodes: {elemMatch: {name: {eq: "Blogs"}}}}}
          ) {
            edges {
              node {
                id
                title
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                uri
              }
            }
          }
        }`
    )
    return data
}