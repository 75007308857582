import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql, Link } from "gatsby"
import { useRightSideBlog } from "../hooks/usePopularBlogQuery"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { StaticImage } from 'gatsby-plugin-image'
//import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEARCHCOMPONENT from '../components/BlogSection/SearchComponet'
import _ from 'lodash'


export default function BlogPost({ data }) {
    const { popularBlog } = useRightSideBlog();
    //console.log('dataaaaaa::: ', data)
    const { wpPost, allWpPost } = data
    //console.log('wpPost------> ', wpPost)
    var blogPostWithoutNullContent = allWpPost.edges
    //var blogPostWithoutNullContent = _.reject(allWpPost.edges, ['node.content', null]);
    // console.log('blogPostWithoutNullContent: ', blogPostWithoutNullContent);

    let search = undefined
    if (typeof window !== "undefined") {
        search = window.location.search
    } else {
        search = ""
    }
    // console.log('searchhh:: ', search)
    let searchData = []
    const filterBy = (term) => {
        const escapeRegExp = (str) =>
            str.replace('[-A-Z0-9+&@#\/%?=~_|!:,.;]')

        const re = new RegExp(escapeRegExp(term), 'i')
        return person => {
            for (let prop in person.node) {
                if (!person.node.hasOwnProperty(prop)) {
                    continue;
                }
                if (prop === "title") {
                    if (re.test(person.node[prop])) {
                        return true;
                    }
                }

            }
            return false;
        }
    }
    let text = ''
    if (search !== "" && search !== null && search !== undefined) {
        text = search.split("=")
        text = text[1]
        text = text.replace(/[^a-zA-Z ]/g, " ");
        const value = blogPostWithoutNullContent.filter(filterBy(text))
        searchData = value
    }
    //console.log('wpPost::: ', wpPost)
    const listBlogs = popularBlog.edges
    // console.log('listBlogs:: ', listBlogs)

    // if (!wpPost.featuredImage ||
    //     !wpPost.featuredImage.node) return null //Or any React component
    const featuredImage =  data.wpPost.featuredImage && data.wpPost.featuredImage.node && data.wpPost.featuredImage.node.mediaItemUrl
    //console.log('featuredImage:: ',featuredImage)

    return (
        <Layout>
            <Seo post={wpPost} />
            <section className="pageHeader blogs-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">

                            {
                                search !== "" && search !== null && search !== undefined
                                    ?
                                    <>
                                        <nav>
                                            <ol className='breadcrumb'>
                                                <li className='breadcrumb-item'><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item"><Link to="/blogs/">Webuters Blog</Link></li>
                                                <li className="breadcrumb-item active">Search</li>
                                            </ol>
                                        </nav>
                                        <div>
                                            <h6 className='pagetitle__heading'>Search::: {text}</h6>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <nav>
                                            <ol className='breadcrumb'>
                                                <li className='breadcrumb-item'><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item"><Link to="/blogs/">Webuters Blog</Link></li>
                                                <li className='breadcrumb-item active'>{wpPost.title}</li>
                                            </ol>
                                        </nav>

                                        <h1 className="pagetitle__heading entry-header-inner">Webuters Blog</h1>
                                    </>
                            }


                        </div>
                    </div>
                </div>
            </section>

            {
                search !== "" && search !== null && search !== undefined ?
                    <>
                        <section id="content" className="main-blog-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-12 blog-col">
                                                <article id="post-3288">
                                                    {
                                                        searchData && searchData.length > 0 ? searchData.map((blg, i) => {
                                                            return (
                                                                <div className="post-heading" key={i}>
                                                                    <h5 className="blog-heading-title"><a target="_blank" href={blg.node.uri}>{blg.node.title}</a></h5>
                                                                    <h2 className="blog-heading-excerpt">
                                                                        <a target="_blank" href={blg.node.uri}>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{ __html: blg.node.excerpt }}
                                                                            >
                                                                            </div>
                                                                        </a>
                                                                    </h2>
                                                                </div>
                                                            )
                                                        })
                                                            : searchData && searchData.length === 0 ?
                                                                <div>
                                                                    No Data Found
                                                                </div>
                                                                : null
                                                    }



                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <div className="main-blog-section main-innerBlog-sec">
                        <div className="container section-blog-title">
                            <div className="row">
                                <div className="col-sm-12 col-md-9 col-lg-9">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <h1 className="pagetitle__headin">
                                                {wpPost.title}
                                            </h1>
                                            <ul className="post-meta">
                                                <li className="post-author meta-wrapper">
                                                    <span className="meta-text">
                                                        By <a>{wpPost.author.node.name}</a>
                                                    </span>
                                                </li>
                                                <li className="post-author meta-wrapper">
                                                    <span className="meta-text">
                                                        <a>{wpPost.date}</a>
                                                    </span>
                                                </li>
                                            </ul>
                                            <br />
                                            
                                            {featuredImage == null ? null :  <img src={featuredImage} style={{ marginBottom: '30px;' }} /> }
                                            {/* <img src={featuredImage} style={{ marginBottom: '30px;' }} /> */}
                                            <div key={wpPost.id} dangerouslySetInnerHTML={{ __html: wpPost.content }} />
                                        </div>
                                    </div>
                                    <div className="comments-wrapper section-inner">
                                        <div className="wp-comment-designer-lite-wrap wpcd-template-2" data-template-demo="template-2">
                                            <input type="hidden" id="wpcd-current-post-id" value="3288" />
                                            <input type="hidden" id="wpcd-hide-replies" value="" />
                                            <div id="comments" className="wpcd-comments-area">

                                                <div className="wpcd-comment-listing-wrap" id="wpcd-comment-listing-wrap">
                                                    {
                                                        wpPost && wpPost.comments && wpPost.comments.nodes && wpPost.comments.nodes.length > 0 ?
                                                            <div className="wpcd-comments-title">{wpPost && wpPost.comments && wpPost.comments.nodes && wpPost.comments.nodes.length} Thoughts on {wpPost && wpPost.title}</div>
                                                            : null
                                                    }

                                                    <div className="wpcd-comment-list-inner">
                                                        <div className="wpcd-comment-listing-wrapper">
                                                            {
                                                                wpPost && wpPost.comments && wpPost.comments.nodes && wpPost.comments.nodes.length > 0 && wpPost.comments.nodes.map((blogPst, i) => {
                                                                    return (

                                                                        <ul className="wpcd-comment-list " data-comment-id="599" key={i}>
                                                                            <div className="wpcd-comment-template wpcd-comment-template-2 wpcd-clearfix" id="wpcd-unique-comment-599">
                                                                                <div className="wpcd-comment-gravatar">
                                                                                    <StaticImage src="../images/user_default.png" alt=""/>
                                                                                </div>
                                                                                <div className="wpcd-body">
                                                                                    <div className="wpcd-top">
                                                                                        <div className="wpcd-author-name">{blogPst.author.node.name}h</div>
                                                                                        <div className="wpcd-date-time" data-time="2021-01-06 13:37:44">
                                                                                            <div className="wpcd-date">
                                                                                                {blogPst.date}
                                                                                            </div>
                                                                                            {/* <div className="wpcd-time">
                                                                                                1:37pm
                                                                                            </div> */}
                                                                                        </div>
                                                                                        <div className="wpcd-comment wpcd-comment-content-599" id="wpcd-comment-599">
                                                                                            <div
                                                                                                key={blogPst.id}
                                                                                                dangerouslySetInnerHTML={{ __html: blogPst.content }} />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </ul>

                                                                    )
                                                                })}

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-sm-0 col-md-3 col-lg-3">
                                    <aside id="blog-sidebar" className="sidebar-blog">
                                        <SEARCHCOMPONENT allWpPost={allWpPost} />
                                        <div className="popular-posts-sr">
                                            <ul className="wpp-list wpp-cards blog-ul">
                                                {listBlogs.map((listBlog, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <img width="45" height="45" src={listBlog && listBlog.node && listBlog.node.featuredImage && listBlog.node.featuredImage.node && listBlog.node.featuredImage.node.sourceUrl} />
                                                            <div className="wpp-item-data">
                                                                <a className="wpp-post-title" target="_blank" href={listBlog && listBlog.node && listBlog.node.uri}>
                                                                    {listBlog.node.title}
                                                                </a>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
            }


        </Layout>
    )
}
export const query = graphql`
  query($slug: String!) {
    wpPost(slug: { eq: $slug }) {
     id
     uri
     title
     date(formatString: "MMMM DD YYYY")
        seo {
                title
                metaDesc
                focuskw
                metaKeywords
                metaRobotsNofollow
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
    comments {
      nodes {
        content
        type
        id
        date(formatString: "DD MMMM YYYY, h:mm a")
        author {
          node {
            name
          }
        }
      }
    }
    categories {
      nodes {
        id
        name
        link
      }
    }
    content
    featuredImage {
      node {
        mediaItemUrl
      }
    }
    author {
          node {
            name
          }
        }
  }
  allWpPost(
    filter: {categories: {nodes: {elemMatch: {name: {eq: "Blogs"}}}}, , status: {eq: "publish"}}
  ) {
        edges {
          node {
            author {
              node {
                name
              }
            }
            categories {
              nodes {
                name
              }
            }
            content
            date(formatString: "DD MMMM YYYY, h:mm a")
            excerpt
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            uri
          }
        }
      }
  }
`